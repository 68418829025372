import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../pages/Login.vue";
import DashboardOutlet from "../pages/DashboardOutlet.vue";
import Photos from "../pages/Photos.vue";
import TodoList from "../pages/TodoList.vue";
import Notif from "../pages/Notif.vue";
import Slider from "../pages/Slider.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/DashboardOutlet/:sales_id",
    name: "DashboardOutlet",
    component: DashboardOutlet,
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("token") == null) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/Photos/:sales_id/:outlet_id",
    name: "Photos",
    component: Photos,
  },
  {
    path: "/TodoList/:sales_id",
    name: "TodoList",
    component: TodoList,
  },
  {
    path: "/Notif/:sales_id",
    name: "Notif",
    component: Notif,
  },
  {
    path: "/Slider/:sales_id/:id",
    name: "Slider",
    component: Slider,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
