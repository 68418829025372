<template>
  <div class="mx-3">
    <div class="row justify-content-center m-0">
      <div class="col-md-12 col-12 p-0 m-0">
        <TitleHeader
          title="Foto atau upload display rak"
          caption="Foto dilakukan hanya sekali setiap bulan"
        />
        <form
          action
          @submit="formSubmit"
          class="mb-5 pb-4"
          v-if="filteredImage.status != status"
        >
          <div class="form-row mt-4 px-0 mx-0">
            <div
              v-bind:class="[
                'form-group justify-content-center grid-image   mb-3 p-0 col-md-12 col-12',
                { cameraform: data_outlet.file == null }
              ]"
            >
              <div class>
                <image-uploader
                  v-if="data_outlet.file == null"
                  v-model="data_outlet.file"
                  :debug="1"
                  :maxWidth="512"
                  :quality="0.3"
                  :autoRotate="false"
                  outputFormat="string"
                  :preview="false"
                  :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                  capture="environment"
                  accept="video/*, image/*"
                  doNotResize="['gif', 'svg']"
                  @input="setImage"
                >
                  <label for="fileInput" slot="upload-label" class="mt-3 mb-0">
                    <figure class="m-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="300"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <path
                          class="path1"
                          d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                        />
                      </svg>
                    </figure>
                    <span
                      class="upload-caption d-flex justify-content-center pb-3 text-purple font-weight-normal"
                      >{{ hasImage ? "Replace" : "Upload Foto Rak" }}</span
                    >
                  </label>
                </image-uploader>
                <div>
                  <img
                    v-bind:src="data_outlet.file"
                    class="w-100 h-100 p-0 m-0"
                    alt
                  />
                </div>
              </div>
              <div
                class="d-flex justify-content-center position-fixed divSpinner"
                v-if="isLoading == true"
              >
                <div
                  class="spinner-border text-purple position-absolute iconSpinner"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
          <button
            @click="formSubmit"
            data-toggle="modal"
            :data-target="'#todo'"
            type="button"
            class="btn bg-button font-weight-normal text-white col-md-12 col-12 mt-3"
          >
            Submit
          </button>
        </form>
        <div class="col-12 mt-3 mb-camera  camerablack " v-else>
          <p class="m-0 py-4 font-14 text-danger">
            Anda tidak dapat melakukan upload foto karena sudah terverifikasi
            untuk bulan ini
          </p>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      :id="'todo'"
      data-backdrop="static"
      tabindex="-1"
      role="dialog"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content ">
          <div
            class="modal-header justify-content-center py-2 bg-modal"
          >
            <h6 class="modal-title py-0"  v-if="upload == true" >
              Foto berhasil diupload
            </h6>
            <h6 class="modal-title py-0"  v-if="gagal == true" >
              Foto gagal diupload
            </h6>
            <h6 class="modal-title py-0"  v-if="verifikasi == true">
              Foto sudah diverifikasi
            </h6>
          </div>
          <div class="modal-body text-center py-1" v-if="upload == true">
            <img src="../assets/9925-check-purple.gif" class="w-25" alt="" />
          </div>
          <div class="modal-body text-center py-1" v-if="gagal == true">
            <img src="../assets/4903-failed.gif" class="w-25" alt="" />
          </div>
          <div class="modal-body text-center py-1" v-if="verifikasi == true">
            <img src="../assets/9925-check-purple.gif" class="w-25" alt="" />
          </div>
          <div class="modal-footer justify-content-center py-0">
            <button type="button" class="btn secondary py-1" @click="refreshPage">
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TitleHeader from "./TitleHeader";

export default {
  name: "Registrasi",
  components: {
    TitleHeader
  },
  data() {
    return {
      data_outlet: [],
      file: null,
      error: [],
      currenttime: "",
      status: "SUDAH TERVERIFIKASI",
      isLoading: false,
      success: false,
      upload: false,
      gagal: false,
      verifikasi: false
    };
  },
  methods: {
    refreshPage() {
      window.location.reload();
    },
    setImage: function(file) {
      (this.hasImage = true), (this.file = file);
    },
    async formSubmit(file) {
      this.isLoading = true;
      console.log(file);
      const data = {
        foto: this.file,
        outlet_id: this.$route.params.outlet_id
      };
      console.log(data);
      try {
        let res = await axios({
          method: "post",
          url: `${process.env.VUE_APP_URL}outlet/image`,
          data: data,
          headers: {
            Authorization: localStorage.token
          }
        });
        if (res.status === 200) {
          // window.location.reload();
          this.isLoading = false;
          // eslint-disable-next-line no-constant-condition
          this.upload = true;
          // this.$router.push(`/DashboardOutlet/${this.$route.params.sales_id}`);
        } else {
          this.isLoading = false;
          this.gagal = true;
        }
        return res.data;
      } catch (error) {
        if (error.response.status === 400) {
          this.isLoading = false;
          this.verifikasi = true;
          // this.$router.push(`/DashboardOutlet/${this.$route.params.sales_id}`);
        }
      }
    }
  },
  created() {
    this.$store.dispatch("getImage", {
      outlet_id: this.$route.params.outlet_id,
      token: localStorage.token
    });
  },
  computed: {
    images() {
      console.log(this.$store.state.images);
      return this.$store.state.images;
    },
    filteredImage() {
      const date = new Date();
      const month = date.getMonth() + 1;
      const image = this.images.filter(data => data.id === month);
      console.log(image.status);
      return image[0];
    }
  }
};
</script>

<style scoped>
.modal-dialog {
  position: relative;
  width: 80%;
  height: 8vh;
  top: 20% !important;
  left:0 !important;
  right: 0 !important;
  margin: auto!important;
  pointer-events: none;
}
.iconSpinner {
  top: 50vh;
  z-index: 120;
}
.divSpinner {
  z-index: 100;
  right: 0;
  left: 0;
  top: 0vh;
  background-color: #b684ff;
  min-width: 360px;
  max-width: 750px;
  margin: 0 auto !important;
  min-height: 100vh;
  backdrop-filter: blur(1px);
  opacity: 0.4;
}
.icon-user {
  height: 60px;
}

/deep/ .fileinput {
  display: none;
}

.text-purple {
  color: #4e37b2;
}

.bg-button {
  background-color: #4e37b2;
  box-shadow: 2px 4px 12px rgba(78, 55, 178, 0.35);
}

.theme-color {
  background-color: #653366;
}

.icon-user {
  height: 60px;
}

.theme-color {
  background-color: #653366;
}

.cameraform {
  border: 1px solid red !important;
}

.camerablack {
  border: 1px solid #d3d3d3;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.custom-field {
  position: relative;
  font-size: 14px;
  padding-top: 20px;
  margin-bottom: 5px;
}

.custom-field input {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f2f2f2;
  padding: 12px;
  border-radius: 3px;
  outline: none;
  font-size: 14px;
}

.custom-field .placeholder {
  position: absolute;
  left: 12px;
  top: calc(50% + 10px);
  transform: translateY(-50%);
  color: #d3d3d3;
  transition: top 0.3s ease, color 0.3s ease, font-size 0.3s ease;
}

.custom-field input:valid + .placeholder,
.custom-field input:focus + .placeholder {
  top: 10px;
  font-size: 10px;
  color: #222;
}

/* ONE */
.custom-field.one input {
  background: none;
  border: 2px solid #ddd;
  transition: border-color 0.3s ease;
}

.custom-field.one input + .placeholder {
  left: 8px;
  padding: 0 5px;
}

.custom-field.one input:valid,
.custom-field.one input:focus {
  border-color: #d3d3d3;
  transition-delay: 0.1s;
}

.custom-field.one input:valid + .placeholder,
.custom-field.one input:focus + .placeholder {
  top: 20px;
  font-size: 10px;
  color: #d3d3d3;
  background: #fff;
}

.grid-image {
  display: grid;
}

.fileinput {
  display: none;
}

input:invalid {
  border: 1px solid red !important;
}

select:invalid {
  border: 1px solid red !important;
}

span .placeholder:invalid {
  color: red !important;
}

.btn-theme {
  background-color: #000099 !important;
  color: white !important;
}

.form-control {
  height: calc(2.1em + 0.75rem + 2px) !important;
}

.box-camera {
  width: 100px;
}
.mb-camera {
  margin-bottom: 4rem;
}
.bg-modal {
  background: #5c27aa;
  color: #ffffff;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .form-control {
    height: calc(2.5em + 0.75rem + 2px) !important;
  }

  select:invalid {
    border: 1px solid red !important;
  }

  input:invalid {
    border: 1px solid red !important;
  }

  span .placeholder:invalid {
    color: red !important;
  }

  .font10 {
    font-size: 10px;
  }

  .icon-user {
    height: 50px;
  }

  .font-12 {
    font-size: 12px;
  }

  .font-14 {
    font-size: 14px;
  }

  .font-16 {
    font-size: 16px;
  }

  .font-18 {
    font-size: 18px;
  }
}
</style>

