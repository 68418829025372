<template>
    <div>
        <Header :title="this.$route.params.outlet_id" :contentFor="'foto'"/>
        <UploadPhoto/>
        <ListPhoto :image="myLastArray"/>
       <div class="d-flex mt-3 mb-button col-12 mr-auto"> <button type="button" class="btn bg-button" @click="selected=12"><p class="my-0 font-14">Lihat Semua</p></button></div>
    </div>
</template>

<script>
    import ListPhoto from "../components/ListPhoto.vue";
    import UploadPhoto from "../components/UploadPhoto.vue";
    import Header from "../components/Header.vue";

    export default {
        data() {
            return {
                selected: 6
            }
        },
        components: {Header, UploadPhoto, ListPhoto},
        mounted() {
            this.$store.dispatch("getImage", {
                outlet_id: this.$route.params.outlet_id,
                token: localStorage.token
            })
        },
        computed: {
            images() {
                // console.log(this.$store.state.images)
                return this.$store.state.images
            },
            myLastArray() {
                if (!Array.isArray(this.images)) {
                    return [];
                }
                return this.images.slice(0, this.selected);
            },
        },

    };
</script>

<style scoped>
    .bg-button {
        background-color: #4e37b2;
        box-shadow: 2px 4px 12px rgba(78, 55, 178, 0.35);
        color:white ;
    }
    .btn:hover {
        color: white;
        text-decoration: none;
    }
    .mb-button{
        margin-bottom: 8rem;
    }
    @media screen and (max-width: 1000px) {
        .mb-button{
            margin-bottom: 6rem;
        }
        .font-14{
            font-size: 14px;
        }
    }
</style>
